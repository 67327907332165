import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {addCardInventorySchema} from '../../../../../../schemas'
import CardSearch from '../../../../../SharedComponent/CardSearch/CardSearch'
import {
  convertISODateWithSlashed,
  formatAMPM,
  GetCurrentUser,
} from '../../../../../../utils/DefaultFunctions'
import {STRINGS} from '../../../../../../utils/Enums'
import dayjs, {Dayjs} from 'dayjs'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {API} from '../../../../../../utils/services'
import {useDispatch, useSelector} from 'react-redux'
import {
  AddCardBranch,
  CardChannel,
  CardStatus,
  CardType,
  NativeBranch,
  ResetState,
} from '../../../BranchSlice/branch.slice'
import {useNavigate} from 'react-router-dom'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const OtherOrganization = () => {
  const navigate = useNavigate()
  const {branchSlice} = useSelector(({Reducers}) => Reducers)

  const initialValues = {
    acq_bank: '',
    acq_branch: '',
    note: '',
    number: '',
    customerName: '',
    expiryState: '',
    cardType: '',
    cif: '',
    cardStatus: '',
    captureReason: 'Anonymous',
    issueBank: '',
    issueBranch: '',
    captureDate: '',
  }

  const [cardOwnerBank, setCardOwnerBank] = useState()
  const [cardOwnerBankName, setCardOwnerBankName] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [organizationId, setOrganizationId] = useState()
  const [currentCardOwnerBankId, setCurrentCardOwnerBankId] = useState('')

  const [acquirerCity, setAcquirerCity] = useState('')

  const [cardTypes, setCardTypes] = useState()
  const [cardChannels, setCardChannels] = useState()
  const [currentCardTypeId, setCurrentCardTypeId] = useState()
  const [currentCardChannelId, setCurrentCardChannelId] = useState()

  const [cardStatus, setCardStatus] = useState()
  const [currentCardStatusId, setCurrentCardStatusId] = useState('')

  const [issuerBranch, setIssuerBranch] = useState([])

  const [currentNativeBranchId, setCurrentNativeBranchId] = useState('')

  let current_id = new URL(window.location).searchParams.get('id')

  let dispatch = useDispatch()

  let user = GetCurrentUser().api_token

  const [date, setDate] = useState(dayjs().format())
  const [dateSelected, setDateSelected] = useState(false)
  const [convertedDate, setConvertedDate] = useState(dayjs('2023-01-04T00:00:00'))

  const handleDateChange = (newValue) => {
    setDateSelected(true)

    console.log('handleDateChange', newValue)
    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let selectedTime = formatAMPM(new Date(newValue))
    let convertedDateTime = new Date(`${selectedDate} ${selectedTime} UTC`).toISOString()
    setDate(newValue)
    setConvertedDate(convertedDateTime)
  }

  const {values, errors, touched, handleSubmit, handleChange, handleBlur} = useFormik({
    initialValues,
    validationSchema: addCardInventorySchema,
    onSubmit: (values) => {
      console.log('🚀 ~ file: OtherBank.js:20 ~ OtherBank ~ values', values)
    },
  })

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  useEffect(() => {
    if (user !== null) {
      API.get('/card/cardtypes', {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          setCardTypes(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/Card/cardchannels', {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          setCardChannels(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      let body = {
        pageNumber: 1,
        pageSize: 100,
      }

      API.post('/Bank/search', body, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          console.log(res)
          setCardOwnerBankName(res.data.result.data.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/Organization?PageNumber=1&PageSize=100', {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          console.log(res)
          setOrganizations(res.data.result.data.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/card/cardstatus', {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          setCardStatus(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      // let body = {
      //   pageNumber: 1,
      //   pageSize: 20,
      // }

      API.post('/Branch/Search', body, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          setIssuerBranch(res.data.result.data.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [])

  useEffect(() => {
    API.post(
      '/Branch/Search',
      {
        pageNumber: 1,
        pageSize: 100,
      },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    )
      .then((res) => {
        res.data.result.data.$values.forEach((element) => {
          if (element.id === current_id) {
            setCardOwnerBank(element.branchName)
            setAcquirerCity(element.city)
          }
        })
      })
      .catch((err) => {
        console.log(err, 'err')
      })

    currentCardTypeId && dispatch(CardType(currentCardTypeId))
    currentCardStatusId && dispatch(CardStatus(currentCardStatusId))
    currentNativeBranchId && dispatch(NativeBranch(currentNativeBranchId))
    currentCardChannelId && dispatch(CardChannel(currentCardChannelId))
  }, [
    currentCardTypeId,
    currentCardStatusId,
    currentNativeBranchId,
    branchSlice.cardTypeId,
    currentCardChannelId,
  ])

  const formSubmit = (values) => {
    let {acq_bank, note, captureReason, number, customerName, expiryState, cif, cnic} = values
    let {cardTypeId, cardStatusId, nativeBranchId, cardChannelId} = branchSlice
    // const isoDate = date.toISOString()

    let body = {
      convertedDate,
      current_id,
      cardChannelId,
      customerName,
      number,
      expiryState: expiryState.toString(),
      cif,
      cnic,
      cardTypeId,
      cardStatusId,
      nativeBranchId,
      captureReason,
      note,
      user,
      currentCardOwnerBankId,
      organizationId,
    }

    console.log(body, 'body')

    //validation for date
    if (!dateSelected) {
      toast.error('Select Captured Date!', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for customer name
    if (!customerName) {
      toast.error('Customer name is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validations for card expiry
    if (!expiryState) {
      toast.error('Card Expiry is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length !== 4) {
      toast.error('Card Expiry is not correct', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length == 4) {
      const firstTwoChars = expiryState?.slice(0, 2)
      if (firstTwoChars == '00') {
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
      if (Number(firstTwoChars) > 12) {
        console.log('ttt')
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
    }

    //validation for issuer bank
    if (!organizationId) {
      toast.error('Organization is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (!note) {
      toast.error('Notes is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    dispatch(AddCardBranch(body))
    ResetState()
    // if (number?.length < 10 || number?.length > 25) {
    //   toast.error('Card Number must be between 10 and 25 digits', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    //   return
    // }
    // if (number.length > 0) {
    //   if (!number?.length === 16 || !number?.length === 20) {
    //     toast.error('Card number must be 16 or 20 digits', {
    //       position: 'bottom-right',
    //       theme: 'colored',
    //     })
    //     return
    //   }
    // }
    // if (dateSelected && note && expiryState && customerName && organizationId) {
    //   const firstTwoChars = expiryState.slice(0, 2)

    //   if (firstTwoChars != '00') {
    //     dispatch(AddCardBranch(body))
    //   } else {
    //     toast.error('Month must be between one and twelve', {
    //       position: 'bottom-right',
    //       theme: 'colored',
    //     })
    //   }
    // } else {
    //   toast.error('Fill all mandatory fields!', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    // }

    // if (!dateSelected) {
    //   toast.error('Select Captured Date!', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    // }

    // ResetState()
  }

  useEffect(() => {
    if (branchSlice.cardAddResult) {
      navigate(`${STRINGS.ROUTES.BRANCH.MAIN}?id=${current_id}`)
      //reset func
    }
    // if(!branchSlice.cardAddResult){
    //   toast.error("Fill all mandatory fields!", {
    //     position: "bottom-right",
    //     theme: "colored",
    //   });
    // }
  }, [branchSlice.cardAddResult])

  return (
    <div
      className='me-3'
      style={{
        backgroundColor: 'white',
        border: '1px solid #E4E4E4',
        borderRadius: '10px',
        marginTop: '-2rem',
      }}
    >
      <div className='my-5' style={{padding: '10px'}}>
        <form class='form fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit}>
          <div className='d-flex mb-5'>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Capture Date / Time</strong>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DateTimePicker
                    // label='Date&Time picker'
                    maxDate={date}
                    minDate={minDate}
                    className='form-control form-control-lg form-control-solid'
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>Card Number</strong>
              </label>
              <input
                type='number'
                class='form-control form-control-solid'
                name='number'
                id='number'
                maxLength={20}
                onKeyPress={(e) => {
                  if (e.target.value.length === 20) {
                    e.preventDefault()
                  }
                }}
                value={values.number}
                onChange={handleChange}
                placeholder='1111 2222 3333 4444'
                onBlur={handleBlur}
              ></input>
              {errors.number && touched.number ? (
                <p className='form-error text-danger my-3'>{errors.number}</p>
              ) : null}
            </div>
          </div>

          <div className='d-flex my-10'>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Customer Name</strong>
              </label>
              <input
                type='text'
                name='customerName'
                id='customerName'
                value={values.customerName}
                onBlur={handleBlur}
                onChange={handleChange}
                class='form-control form-control-lg form-control-solid'
                placeholder='Muhammad Juanid Ahmed'
                // onChange={(e) => setCustomerName(e.target.value)}
              />
              {errors.customerName && touched.customerName ? (
                <p className='form-error text-danger my-1'>{errors.customerName}</p>
              ) : null}
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>CNIC</strong>
              </label>
              <input
                type='number'
                name='cnic'
                class='form-control form-control-lg form-control-solid'
                placeholder='4210178912345'
                maxLength={13}
                onKeyPress={(e) => {
                  if (e.target.value.length === 13) {
                    e.preventDefault()
                  }
                }}
                value={values.cnic}
                onChange={handleChange}
                onBlur={handleBlur}
                // onChange={(e) => setCnic(e.target.value)}
              />
              {errors.cnic && touched.cnic ? (
                <p className='form-error text-danger my-3'>{errors.cnic}</p>
              ) : null}
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Card Expiry</strong>
              </label>
              <input
                type='text'
                name='expiryState'
                id='expiryState'
                maxLength={4}
                onKeyPress={(e) => {
                  if (e.target.value.length === 4) {
                    e.preventDefault()
                  }
                }}
                class='form-control form-control-lg form-control-solid'
                placeholder='0223'
                value={values.expiryState}
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={(e) => setCardExpiryState(e.target.value)}
              />
              {errors.expiryState && touched.expiryState ? (
                <p className='form-error text-danger my-1'>{errors.expiryState}</p>
              ) : null}
            </div>
          </div>

          <div className='d-flex my-10'>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>Acquiring Bank</strong>
              </label>
              <input
                type='text'
                name='acq_bank'
                id='acq_bank'
                class='form-control form-control-lg form-control-solid'
                placeholder='Bank Name'
                value='Samba Bank'
                readOnly
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label '>
                <strong>Acquiring Branch</strong>
              </label>
              <input
                type='text'
                name='acq_branch'
                id='acq_branch'
                class='form-control form-control-lg form-control-solid'
                placeholder='Branch Name'
                value={cardOwnerBank}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label '>
                <strong>Acquiring City</strong>
              </label>
              <input
                type='text'
                name='company'
                class='form-control form-control-lg form-control-solid'
                placeholder='Bank Name'
                value={acquirerCity}
                readOnly
              />
            </div>
          </div>

          <div className='d-flex my-10'>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Organization</strong>
              </label>
              <select
                name='cardType'
                id='cardType'
                // value={values.cardType}
                onChange={(e) => {
                  setOrganizationId(e.target.value)
                }}
                // onBlur={handleBlur}
                // onChange={handleChange}
                class='form-select form-select-solid'
                data-control='select2'
                data-placeholder='Select Card Type'
                data-hide-search='true'
              >
                <option selected hidden>
                  Select Organization
                </option>

                {organizations &&
                  organizations.map((item, index) => {
                    return (
                      <>
                        <option value={item.id}>{item.organization}</option>
                      </>
                    )
                  })}
              </select>
            </div>

            {/* <div className='me-3' id='w-500'>
              <label for="exampleInputEmail1" className="form-label"><strong>Issuer Branch</strong>
              </label>
              <input
                type='text'
                name='issueBranch'
                id='issueBranch'
                value={values.issueBranch}
                onBlur={handleBlur}
                onChange={handleChange}
                class='form-control form-control-lg form-control-solid'
                placeholder='Issuer Branch'
                // onChange={(e) => setIssuerBranch(e.target.value)}
              />
            </div> */}
          </div>

          <div className='d-flex my-5'>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>Card Type</strong>
              </label>
              <select
                name='cardType'
                id='cardType'
                // value={values.cardType}
                onChange={(e) => {
                  setCurrentCardChannelId(e.target.value)
                }}
                // onBlur={handleBlur}
                // onChange={handleChange}
                class='form-select form-select-solid'
                data-control='select2'
                data-placeholder='Select Card Type'
                data-hide-search='true'
              >
                <option selected hidden>
                  Select Card Type
                </option>
                {cardChannels &&
                  cardChannels.map((item, index) => {
                    return (
                      <>
                        <option value={item.id}>{item.description}</option>
                      </>
                    )
                  })}
              </select>
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>CIF</strong>
              </label>
              <input
                type='number'
                onKeyPress={(e) => {
                  console.log(e.target.value.length)
                  if (e.target.value.length === 6) {
                    e.preventDefault()
                  }
                }}
                name='cif'
                id='cif'
                class='form-control form-control-lg form-control-solid'
                placeholder='12345'
                value={values.cif}
                onBlur={handleBlur}
                onChange={handleChange}
                // onclick = {() => (change)}
                // onChange={(e) =>
                //   setCIFNumber(e.target.value)
                //   (change)
                // }
              />
            </div>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label'>
                <strong>Reason For Captured</strong>
              </label>
              <input
                type='text'
                name='captureReason'
                id='captureReason'
                value='Anonymous'
                readOnly
                // onBlur={handleBlur}
                // onChange={handleChange}
                class='form-control form-control-lg form-control-solid'
                placeholder='Reason For Captured'
                // onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
          </div>

          <div className='d-flex my-10'>
            {/* <div className='me-3' id='w-500'>
              <label for="exampleInputEmail1" className="form-label required"><strong>Card Status</strong>
              </label>
              <select
                name='cardStatus'
                id='cardStatus'
                // value={values.cardStatus}
                onChange={(e) => setCurrentCardStatusId(e.target.value)}
                // onBlur={handleBlur}
                // onChange={handleChange}
                class='form-select form-select-solid'
                data-control='select2'
                data-placeholder='Select Card Type'
                data-hide-search='true'
              >
                <option selected hidden>
                  Select Card Status
                </option>
                {cardStatus &&
                  cardStatus.map((item, index) => {
                    return (
                      <>
                        <option value={item.id}>{item.description}</option>
                      </>
                    )
                  })}
              </select>
              {errors.cardStatus && touched.cardStatus ? (
                <p className='form-error text-danger my-1'>{errors.cardStatus}</p>
              ) : null}
            </div> */}
          </div>

          <div className='d-flex  ' style={{marginBottom: '5rem'}}>
            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Note</strong>
              </label>
              <textarea
                class='form-control form-control-solid'
                name='note'
                id='note'
                aria-label='With textarea'
                spellcheck='false'
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
              {errors.note && touched.note ? (
                <p className='form-error text-danger my-3'>{errors.note}</p>
              ) : null}
            </div>
          </div>

          <button
            onClick={() => {
              values && formSubmit(values)
            }}
            type='onSubmit'
            class='btn btn-primary btn-sm'
            style={{float: 'right', marginBottom: '3rem'}}
          >
            Submit
          </button>
        </form>
      </div>
      <div className=' my-3 mb-5'>{/* <CityTable data={country} type="country" /> */}</div>
      <ToastContainer />
    </div>
  )
}

export default OtherOrganization
